@import '~antd/dist/antd.dark.less';
@import './ant-custom.less';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-layout-content {
  background-color: #6e3bb3;
}

.ant-layout-footer {
  background-color: #5f23ae;
}

a {
  color: #ffd618 !important;
}

li {
  color: #ffd618 !important;
  //border-bottom: 2px solid #ffd618 !important;
}

.ant-menu-item-selected {
  border-bottom: 2px solid #ffd618 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffd618 !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #ad9213 !important;
}

.ant-btn {
  border-color: #ad9213 !important;
}
.ant-btn:hover, .ant-btn:focus {
  color: #ad9213 !important;
}
.ant-switch-checked {
  background-color: #ad9213;
}
.ant-slider-handle:focus {
  border-color: #ad9213;
}

.ant-radio-button-wrapper:hover {
  position: relative;
  color: #ad9213;
}

::selection {
  color: unset;
  background: rgba(0, 0, 0, 0.404) !important;
}
::-moz-selection { /* Code for Firefox */
  color: unset;
  background: rgba(0, 0, 0, 0.404) !important;
}


.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus {
  border-color: #806c11 !important;
  border-right-width: 1px !important;
  z-index: 1;
}

.ant-tabs-tab:hover {
  color: #ad9213 !important;
}

.ant-btn-group > .ant-btn:first-child:not(:last-child), .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child), .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 20px;
}

.ant-btn-group > .ant-btn:only-child {
  border-radius: 20px !important;
}

.white {
  color: rgba(255, 255, 255, 0.8)!important;
}
.white:hover {
  color: #ad9213 !important;
}
@primary-color: #fed617;